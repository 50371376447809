// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: User projects page

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from "@mui/material"
import { 
	DeleteOutlined as DeleteOutlinedIcon,
	AddPhotoAlternateOutlined as NewShadingIcon,
	KeyboardBackspace as KeyboardBackspaceIcon
} from "@mui/icons-material";

import { EDIT_MAP_PAGE } from "App";
import { getMapData, getUserMaps, getUserApiKey, setShadingNameInDB, deleteMap } from "#libs/apis/backend";
import FadeInSection from "#components/FadeSection";
import {ProjectContext} from "#components/Contexts";
import ProjectBar from "#components/ProjectBar";

import NewProject from "#assets/new_project.png"
import "#styles/pages/ProjectsPage"
import ResetShadingModal from "#components/ResetShadingModal";

const MAX_SHADINGS = 5;

interface ShadingRequestProps {
  id: string,
  name: string,
  previewImage: string,
  apiKey: string,
}

/**
 * Projects page
 * @returns  React element
 */
function ShadingsPage(): JSX.Element {
	// Constants
	const SHADINGS_PER_ROW = 3;
	
	const project = useContext(ProjectContext);
	const [shadings, setUserShadings] = React.useState<ShadingRequestProps[]>([]);
	const [hasFetched, setFetched] = React.useState<boolean>(false);
	const [openServerDialog, setOpenServerDialog] = React.useState<boolean>(false);
	const [openNewShadingDialog, setNewShadingDialog] = React.useState<boolean>(false);
	
	// Navigation helpers
	const navigate = useNavigate();
	const navigateToEditPage = () => navigate(EDIT_MAP_PAGE);
	
	// Get user auth key
	const auth = useAuthUser();
	const authData = auth();
	const authKey = authData?.authKey || "";
	
	// Async function to retrieve a list of the user's projects from the database
	const fetchProjects = async () => {
		// console.log(authData)
		const userProjects = await getUserMaps(authKey);
		const apiKey = await getUserApiKey(authKey);

		if (userProjects.length) {
			const projectData = userProjects.map((project: any) => {
				return {
					id: project.mapId,
					name: project.mapName,
					previewImage: `data:image/png;base64,${project.mapThumbnail}`,
					imageHash: Date.now(),
					apiKey: apiKey,		// FIXME: Hack to get api key of user in project context
				};
			});
			setUserShadings(projectData);
			setFetched(true);
		}
	}

	// Creades delete function for target shading
	const createDeleteHandler = (shadingId: string) => {
		return async () => {
			const isDeleteSuccessful = await deleteMap(shadingId);
			if (isDeleteSuccessful) {
				// Remove deleted tile from view.
				setUserShadings(userShadings => userShadings.filter(shading => shading.id !== shadingId))
			}
			else {
				setOpenServerDialog(true);
			}
		}
	}

	// Creates new project with input name
	const handleCreateShading = (newShadingName: string) => {
		project.reset();
		project.projectName = newShadingName;
		navigate(EDIT_MAP_PAGE);
	};

	// If no projects are present on page load, retrieve the projects
	if (!hasFetched) fetchProjects();

	// Create a list of cards from the project list
	const cards = shadings.map((shading: ShadingRequestProps) => 
		<ProjectCard 
      shadingId={shading.id} 
      name={shading.name} 
      previewImage={shading.previewImage} 
      apiKey={shading.apiKey} 
      deleteCallback={createDeleteHandler(shading.id)}
    />
	);

	// Place the cards into the card rows
	const cardRows = []
	for (let i = 0; i < cards.length; i += SHADINGS_PER_ROW) {
		cardRows.push(
			<CardRow>
				{cards.slice(i, i + SHADINGS_PER_ROW)}
			</CardRow>
		);
	}
	
	return <>
		<ProjectBar>
			<div className="projects-page__layout">
				<section className="projects-page__content">
					<Button 
						children="Back"
						onClick={navigateToEditPage} 
						startIcon={<KeyboardBackspaceIcon />}
						sx={{ 'justify-content': "left", maxWidth: "80px" }} 
						/>
					<section className="projects__header">
						<h4>{"My shadings"}</h4>
						<h4>Total: {shadings.length}</h4>
					</section>
					<Button 
						children="New shading"
						onClick={() => setNewShadingDialog(true)} 
						startIcon={<NewShadingIcon />}
						variant="outlined"
						sx={{ 'justify-content': "left", width: "200px", minHeight: "50px", margin: '5px', marginInline: 0, color: "var(--primary-text)", background: "var(--bg-color)", zIndex: 10 }} 
					/>
					<div className="projects__shadow--top" />
					<div className="projects__content">{cardRows}</div>
					<div className="projects__shadow--bottom" />
				</section>
			</div>
		</ProjectBar>
		<DeleteFailedDialog open={openServerDialog} setOpen={setOpenServerDialog} />
		<ResetShadingModal 
			open={openNewShadingDialog} 
			onConfirm={handleCreateShading} 
			onCancel={() => setNewShadingDialog(false)} 
		/>
	</>
}
/**
 * Cards row
 * @param props 
 * @returns  
 */
function CardRow(props: {children: React.ReactNode}) {
	return (
		<FadeInSection>
			<section className="projects__content-row">
				{props.children}
			</section>
		</FadeInSection>
	)
}

/**
 * Base card used for projects
 * @returns 
 */
function ProjectCardBase(props: {children: React.ReactNode, onClick?: () => void}) {
	return (
		<Button className="project-card__base" onClick={props.onClick}>
			{props.children}
		</Button>
	)
}

/**
 * Card for adding a new project.
 * @returns 
 */
function AddProjectCard() {
	const navigate = useNavigate();
	const project = useContext(ProjectContext);

	const handleNewProject = () => {
		project.reset();
		navigate(EDIT_MAP_PAGE)
	};

	return (
		<ProjectCardBase onClick={handleNewProject}>
			<img src={NewProject} alt="New Shading" style={{width: 280, height: 280}}/>
			<h6>New Shading</h6>
		</ProjectCardBase>
	);
}

/***
 * Card for displaying and selecting an existing project
 */
function ProjectCard({shadingId, name, previewImage, apiKey, deleteCallback}: {
	shadingId: string, 
	name: string, 
	previewImage: string, 
	apiKey: string,
	deleteCallback: () => void,
}) {
	const project = useContext(ProjectContext);
	const [shadingName, setShadingName] = React.useState<string>(name);
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);

	const navigate = useNavigate();

  // Load project into context and return to Editing page
	const handleSelected = async () => {
		const res = await getMapData(shadingId)
		project.reset()

		project.mapId = shadingId;
		project._bounds = {
			north: res.mapNorth,
			south: res.mapSouth,
			east: res.mapEast,
			west: res.mapWest,
		};
		project.settings = {
			modelNo: res.mapModelNo, 
			lightRotation: res.mapAngle,
			generalization: res.mapMacro,
			generalizationDetails: res.mapMicro,
			aerialPerspective: res.mapAP,
			slopeDarkness: res.mapContrast,
			elevationRangeMin: res.mapTMin,
			elevationRangeMax: res.mapTMax,
			flatAreaAmount: res.mapFlatAmount,
			flatAreaSize: res.mapFlatSize,
		}; 
		project.elevationModel = res.mapEModel;
		project.projection = res.mapProj;
		project.apiKey = apiKey;
		project.projectName = res.projectName;
			
		navigate(EDIT_MAP_PAGE);
	}
	
  // Delete project from the database
  const deleteShading = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		stopEventPropagation(e);  // Stop click event
		setOpenDeleteDialog(true);
	}

	const stopEventPropagation = (e: React.MouseEvent<HTMLElement, MouseEvent>) => { 
		e.stopPropagation(); 
	}

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => { setShadingName(e.target.value); }

  /**
   * Handle project name updates, reverts to Untitled map if empty
   * @param aShadingName given name for the shading
   */
  const handleNameUpdate = async (aShadingName: string) => {
		// Update the project name in the database

		// Get the text from the input box, check length is not 0
		const newShadingName = (aShadingName.length > 0) ? aShadingName : "Untitled map";
		setShadingName(newShadingName);

		const success = await setShadingNameInDB(shadingId, newShadingName);
		if (!success) {
			// FIXME: have a propper error for this
			console.error("Name update failed");  
		}
	};

	// Handles name update on blur of input element
	const handleBlurNameUpdate = (e: React.FocusEvent<HTMLInputElement>) => {
		handleNameUpdate(e.target.value);
	}

	// Check for enter keypress to handle name change
	const handleEnterNameUpdate = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			const inputElement = e.target as HTMLInputElement;
			handleNameUpdate(inputElement.value);
			inputElement.blur();
		}
	}

	return (
		<>
      <ProjectCardBase onClick={handleSelected}>
        <div className="project-card__image-container">
          <img key={shadingId} src={`${previewImage}`} alt={shadingName} />
        </div>
        <div style={{display: 'grid', gridTemplateColumns: "40px 1fr 40px", width: '100%', paddingTop: '5px'}}>
          <TextField 
						key={shadingId}
            value={shadingName} 
            defaultValue={name}
            onChange={handleChangeName}
            onClick={stopEventPropagation} 
						onKeyDown={handleEnterNameUpdate}
            onBlur={handleBlurNameUpdate}
            required 
            variant="standard" 
            className="project-card__input-label"
            sx={{gridColumn: "2"}}  
            inputProps={{style: {textAlign: 'center', color: 'var(--primary-text)'}}}
            />
          <IconButton onClick={deleteShading} style={{gridColumn: "3", color: "var(--primary-text)"}}>
            <DeleteOutlinedIcon />
          </IconButton>
        </div>
      </ProjectCardBase>
      <DeleteShadingDialog open={openDeleteDialog} setOpen={setOpenDeleteDialog} shadingName={shadingName} handleDelete={deleteCallback}/>
    </>
	);
}

/***
 * Dialog box for requesting OpenTopography API Key.
 */
function DeleteShadingDialog({ open: isOpen, setOpen, shadingName, handleDelete: onDelete }: { 
	open: boolean, 
	setOpen: React.Dispatch<boolean>,
	shadingName: string,  
	handleDelete: () => void,
}) {
  // Close the dialogue box
  const handleClose = () => setOpen(false);

  // Delete project from database
  const handleDelete = () => {
		onDelete()
    handleClose();
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" PaperProps={{ className: "glass--dark solid-fill" }}>
      <DialogTitle>Delete Shading</DialogTitle>
      <DialogContent>
				<h1>Confirm Delete</h1>
        <hr />
        <div style={{ paddingInline: 30 }}>
          <p>{"Do you want to delete the shading: "}<b><i>{shadingName}</i></b>?</p>
        </div>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" className="neutral-button">Cancel</Button>
        <Button onClick={handleDelete} variant="text" className="no-button">Delete</Button>
      </DialogActions>
    </Dialog>
  );
}

/***
 * Dialog box for failing to delete shading from the server.
 */
function DeleteFailedDialog({ open: isOpen, setOpen}: { 
	open: boolean, 
	setOpen: React.Dispatch<boolean>,
}) {
  // Close the dialogue box
  const handleClose = () => setOpen(false);

  return (
    <Dialog open={isOpen}  fullWidth maxWidth="sm" PaperProps={{ className: "glass--dark solid-fill" }}>
      <DialogTitle>Server Error</DialogTitle>
      <DialogContent>
				<h1 >Delete failed</h1>
        <hr />
        <div style={{ paddingInline: 30 }}>
					Unable to delete shading from the server, please try again later.
        </div>
        {/* <DialogContentText>Unable to delete shading from the server, please try again later.</DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="neutral-button">Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShadingsPage;