// Written by: FIT3162 CS Team 1
// Last modified: 19/09/23
// Title: User profile page element

import { CreditsContext, ProjectContext } from "#components/Contexts";
import ProjectBar from "#components/ProjectBar";
import {
    deleteAccount,
    getUserApiKey,
    getUserProjectAmount,
    setUserApiKey
} from "#libs/apis/backend";
import Project from "#libs/Project";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
    Alert,
    Box,
    Button,
    Collapse,
    Container,
    IconButton,
    TextField
} from "@mui/material";
import { googleLogout } from '@react-oauth/google';
import { DOWNLOAD_MAP_PAGE, EDIT_MAP_PAGE, PAYMENT_PAGE, SHADINGS_PAGE } from "App";
import React, { ChangeEvent, useState } from "react";
import {
    useAuthUser, useSignOut,
} from "react-auth-kit";
import { useNavigate } from 'react-router-dom';
import "#styles/pages/UserProfile";


/**
 * Displays the details about the user's account:
 * @param email Email address of Eduard cloud account
 */
function AccountDetails({ email, name }: { email: string, name: string }) {
    return (
        <div>
            <b>{name}</b>
            <p>{email}</p>
        </div>
    );
}


/**
 * Update API key details
 * @param authKey Google Auth key of user account to request backend.
 */
function ApiKeyDetails({ authKey }: { authKey: string }) {
    // States
    const [apiKey, setApiKey] = React.useState<string>("");
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openFailed, setOpenFailed] = React.useState(false);

    // Functions for updating the API key in the database
    const handleApiKeyChange = (e: ChangeEvent<HTMLInputElement>) => setApiKey(e.target.value);

    function handleSetApiKey() {
        const setApiKey = async () => {
            const success = await setUserApiKey(authKey, apiKey);
            (success) ? setOpenSuccess(true) : setOpenFailed(true);
        };

        setApiKey().catch(console.error);
    }

    // Get API key details from database
    React.useEffect(() => {
        // Retrieve API key from database
        const updatePageApiKey = async () => {
            const userApiKey = await getUserApiKey(authKey);
            setApiKey(userApiKey);
        };

        updatePageApiKey().catch(console.error);
    }, [authKey]);

    // Close action for update dialogs
    const ActionButton = (onClickHandler: () => void) => (
        <IconButton aria-label="close" color="inherit" size="small" onClick={onClickHandler}>
            <CloseIcon fontSize="inherit" />
        </IconButton>
    );

    return <>
        <h3 className="custom-h3">OpenTopography API Key</h3>
        <p>A free API key is required to download elevation models from OpenTopography. Create an account and request an API key at OpAenTopography.org, then enter it here: </p>
        <TextField
            id="outlined-required"
            required
            fullWidth
            label="OpenTopography API Key"
            value={apiKey}
            onChange={handleApiKeyChange}
            onBlur={handleSetApiKey}
        />
        <Box sx={{ width: '100%' }}>
            <Collapse in={openSuccess}>
                <Alert
                    severity="success"
                    children="API key updated successfully"
                    action={ActionButton(() => setOpenSuccess(false))}
                    sx={{ mb: 2 }}
                />
            </Collapse>
            <Collapse in={openFailed}>
                <Alert
                    severity="error"
                    children="Error: API key failed to update"
                    action={ActionButton(() => setOpenFailed(false))}
                    sx={{ mb: 2 }}
                />
            </Collapse>
        </Box>
        <br />
        <p>Create an account and request an API key at <a href="https://OpenTopography.org" target="_blank">OpenTopography.org</a>.</p>
    </>;
}


/**
 * Displays current user credits count.
 * @param userCredits The number of Eduard Credits the user account has. 
 */
function CreditsDetails({ userCredits }: { userCredits: number }) {
    // Navigation helper functions
    const navigate = useNavigate();
    const navigateToPaymentPage = () => navigate(PAYMENT_PAGE);
    const navigateToDownloadPage = () => navigate(DOWNLOAD_MAP_PAGE);

    return <>
        <h3 className="custom-h3">Eduard Credits</h3>
        <p>Current number of Eduard Credits: {userCredits}</p>
        <button type="button" className="details-container__button navigate" onClick={navigateToPaymentPage}>Purchase Credits</button>
    </>;
}


/**
 * Displays the current number of active shadings for the user
 * @param authKey Google Auth key of user account to request backend 
 */
function ShadingsDetails({ authKey }: { authKey: string }) {
    // States
    const [userProjects, setUserProjects] = React.useState(0);

    // Get values from database on page load
    React.useEffect(() => {
        // Retrieve number of projects from database
        const updateProjectNumber = async () => {
            const projectAmount = await getUserProjectAmount(authKey);
            setUserProjects(projectAmount);
        };

        updateProjectNumber().catch(console.error);
    }, [authKey]);

    // Navigation helper functions
    const navigate = useNavigate();
    const navigateToProjectsPage = () => navigate(SHADINGS_PAGE);

    // Shadings text
    const shadingsText = (userProjects <= 0) ? "You have no shading yet."
                       : (userProjects === 1) ? "You have one saved shading."
                       : `You have ${userProjects} saved shadings.`;

    return <>
        <h3 className="custom-h3">Shadings</h3>
        <p>{shadingsText}</p>
        <button type="button" className="details-container__button navigate" onClick={navigateToProjectsPage}>My Shadings</button>
    </>;
}


/**
 * Delete account button + confirmation modals
 * @param authKey Google Auth key of user account to request backend.
 * @param userCredits The number of Eduard Credits the user account has. 
 */
function DeleteAccount({ authKey, userCredits }: { authKey: string, userCredits: number }) {
    // Modal states
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [finalWarningModal, setFinalWarningModal] = useState(false)

    // Projects context
    const project = React.useContext<Project>(ProjectContext);

    // Authentication handlers
    const signOut = useSignOut();

    // Functions to handle the dialogue boxes
    const handleOpenConfirmation = () => setShowConfirmationModal(true);
    const handleCloseConfirmation = () => setShowConfirmationModal(false);
    const handleOpenFinalWarning = () => setFinalWarningModal(true);
    const handleCloseFinalWarning = () => setFinalWarningModal(false);

    // Signs user out of account
    function handleSignOut() {
        signOut();
        googleLogout();
    }

    // Functions to delete the user's account from the database
    const handleDeleteAccount = () => {
        handleCloseConfirmation();
        if (userCredits > 0) {
            handleOpenFinalWarning();
        } else {
            handleFinalDeleteAccount();
        }
    };

    const handleFinalDeleteAccount = () => {
        const deleteUser = async () => {
            const success = await deleteAccount(authKey);
            if (success) {
                project.reset();
                setFinalWarningModal(false);
                handleSignOut();
            } else {
                console.log("Error: account deletion failed"); 
            }
        };

        deleteUser().catch(console.error);
    };

    const initialWarningText = (userCredits > 0)
        ? "Deleting your account will result in the loss of all your shadings and your purchased credits."
        : "Deleting your account will result in the loss of all your shadings.";

    return <>
        <button type="button" className="details-container__button delete" onClick={handleOpenConfirmation}>Delete Account</button>
        {/* Delete Account warnings */}
        {showConfirmationModal && (
            <div className="modal__overlay">
                <div className="modal__popup">
                    <p><b>Warning: This cannot be undone.</b></p>
                    <p>{initialWarningText}</p>
                    <div>
                        <button className="modal__button neutral" onClick={handleCloseConfirmation}>Cancel</button>
                        <button className="modal__button no" onClick={handleDeleteAccount}>Delete Account</button>
                    </div>
                </div>
            </div>
        )}
        {finalWarningModal && (
            <div className="modal__overlay">
                <div className="modal__popup">
                    <p><b>Confirmation: This cannot be undone.</b></p>
                    <p>Are you sure you want to delete your account? You will lose {userCredits} credits.</p>
                    <div>
                        <button className="modal__button neutral" onClick={handleCloseFinalWarning}>Cancel</button>
                        <button className="modal__button no" onClick={handleFinalDeleteAccount}>Delete</button>
                    </div>
                </div>
            </div>
        )}
    </>;
}


/**
 * Displays the Users profile email, API keys, credits, shadings, and delete account
 * @returns React element
 */
function UserProfile(): JSX.Element {
    // Credits context
    const { userCredits } = React.useContext(CreditsContext);

    // Auth details
    const auth = useAuthUser();
    const authData = auth();
    const email = authData?.email || "N/A";
    const authKey = authData?.authKey || "";
    const fullName = authData?.name || "N/A";

    // Navigation helper functions
    const navigate = useNavigate();
    const navigateToEditPage = () => navigate(EDIT_MAP_PAGE);

    return (
        <Container className="details-container__root">
            <Button children="Back" onClick={navigateToEditPage} startIcon={<KeyboardBackspaceIcon />} />
            <span className="details-container__header">
                <h1>Account Details</h1>
            </span>
            <div className="details-container__content">
                <AccountDetails email={email} name={fullName} />
                <hr />
                <ApiKeyDetails authKey={authKey} />
                <hr />
                <CreditsDetails userCredits={userCredits} />
                <hr />
                <ShadingsDetails authKey={authKey} />
                <hr />
                <DeleteAccount authKey={authKey} userCredits={userCredits} />
                <hr />
            </div>
        </Container>
    );
}


function UserProfilePage(): JSX.Element {
    return (
        <ProjectBar>
            <UserProfile />
        </ProjectBar>
    );
}

export default UserProfilePage;
