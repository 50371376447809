// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Edit map page

"use client";
import React from "react";
import {
  useAuthUser,
  useIsAuthenticated,
} from "react-auth-kit";
import {
  Panel,
  PanelGroup
} from "react-resizable-panels";
import {
  Button,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip
} from "@mui/material";
import {
  AddPhotoAlternateOutlined as NewShadingIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
  Cached as CachedIcon
} from "@mui/icons-material";

import { ImageRender } from "#libs/types";
import Project from "#libs/Project";
import { CLOSED_RENDER_PAYMENT } from "#libs/sessionStorageKeys";
import { ImageFormat, zipShading } from "#libs/ImageExports";
import {
  cancelRequest,
  fetchElevationData,
  getProgress,
  processCachedTiff,
  setShadingNameInDB,
  spendUserCredit,
  startRequest,
} from "#libs/apis/backend";

import { INFO_PAGE, PRIVACY_POLICY_PAGE } from "App";
import { CreditsContext, ProjectContext } from "#components/Contexts";
import { ConfirmRenderModal, InsufficientFundsModal } from "#components/CreditsModals";
import MapDisplay from "#components/MapDisplay";
import MapProperties from "#components/MapProperties/MapProperties";
import { LabelledProgressSpinner, ProgressSpinner } from "#components/ProgressStatus";
import ProjectBar from "#components/ProjectBar";
import ResetShadingModal from "#components/ResetShadingModal";
import NavigationTileset, {
  CreditsTile,
  SelectAreaTile,
  GettingStartedTile,
  TryDemoTile,
  UserGuideTile,
  ShadingsTile
} from "#components/Tileset";

import "#styles/glass";
import "#styles/pages/EditMapPage";
import "axios";
import { AxiosError } from "axios";


/**
 * Error message modal
 * @param { isOpen, errorMessage, onCancel }
 * @returns  Element
 */
function RenderErrorModal({ isOpen, errorMessage, onCancel }: {
  isOpen: boolean;
  errorMessage: string | null;
  onCancel: () => void;
}) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="popup">
        <h2>Render Failed</h2>
        <p>{errorMessage}</p>
        <p>Please try again.</p>
        <div>
          <button className="no-button" onClick={onCancel}>OK</button>
        </div>
      </div>
    </div>
  );
}

/**
 * Drop down menu for available download formats
 */
function DownloadFormatMenu({
  anchorEl, isOpen, handleClose, handleMenuItemClick
}: {
  anchorEl: HTMLElement | null,
  isOpen: boolean,
  handleClose: () => void,
  handleMenuItemClick: (format: ImageFormat) => void,
}) {
  const imageFormatItems: JSX.Element[] = (
    Object.keys(ImageFormat) as Array<ImageFormat>
  ).map((elem: ImageFormat) => (
    <MenuItem onClick={() => handleMenuItemClick(elem)}>
      {ImageFormat[elem as keyof typeof ImageFormat]}
    </MenuItem>
  ));

  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <InputLabel sx={{paddingX: 2, opacity: 0.5}}>Download Shading</InputLabel>
      {imageFormatItems}
    </Menu>
  );
}


/**
 * Element for displaying new project option dropzone
 */
function NavigationTiles() {
  const onDrop = React.useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("File reading was aborted");
      reader.onerror = () => console.log("File reading has failed");
      reader.onload = () => {
        const binStr = reader.result;
        console.log(binStr);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const isAuthenticated = useIsAuthenticated();

  const tiles = (!isAuthenticated())
    ? [
      <GettingStartedTile />,
      <TryDemoTile />,
    ] : [
      <SelectAreaTile />,
      <ShadingsTile />,
      <CreditsTile />,
      <UserGuideTile />,
    ];

  return (
    <NavigationTileset rowSize={2}>
      {tiles}
    </NavigationTileset>
  );
}


/**
 * Adjust shading settings for render
 * @param setSettings
 * @returns
 */
function ShadingAdjustmentsPanel({ setSettings, disabled } : { setSettings: React.Dispatch<any>, disabled: boolean }) {
  return (
    <div className="settings-panel glass--dark page-content" style={{ minWidth: "300px", maxWidth: "clamp(300px, 30%, 350px)", overflowY: 'clip' }}>
      <Stack className="map-panel" spacing={0.5} sx={{ whiteSpace: "nowrap"}}>
        <MapProperties callback={setSettings} disabled={disabled}/>
      </Stack>
    </div>
  );
}

/**
 * Edits map page
 * @returns React element
 */
function EditMapPage(): JSX.Element {
  const auth = useAuthUser();
  const authData = auth();
  const authKey = authData?.authKey || "";
  const isAuthenticated = useIsAuthenticated();

  const project = React.useContext<Project>(ProjectContext);
  const { userCredits, updatePageCredits } = React.useContext(CreditsContext);

  const [settings, setSettings] = React.useState(project.settings);
  const [hasUpdatedProject, setHasUpdatedProject] = React.useState(false);
  const [renderUrl, setRenderUrl] = React.useState<ImageRender>({
    imageSrc: "",
    imageHash: Date.now(),
    geoTiff: "",
    png: "",
    world: "",
    projection: "",
  });
  const [isDownloading, setDownloading] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isRendering, setRendering] = React.useState<boolean>(false);
  const [hasMapData, setHasMapData] = React.useState<boolean>(false);
  const [requestId, setRequestId] = React.useState<string>("");
  const [progress, setProgress] = React.useState<number>(0);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [shadingName, setShadingName] = React.useState<string>(project.projectName);

  const [showResetShadingModal, setShowResetShadingModal] = React.useState<boolean>(false);
  const [showConfirmRenderModal, setShowConfirmRenderModal] = React.useState<boolean>(false);
  const [showInsufficientFundsModal, setShowInsufficientFundsModal] = React.useState<boolean>(false);
  const [showRenderErrorModal, setShowRenderErrorModal] = React.useState<boolean>(false);

  const isControlsDisabled = (!isAuthenticated() && !project.isDemo) || !hasMapData || isDownloading || isRendering;

  // Call the renderer when the bounds change (first time render)
  React.useEffect(() => {
    if (project.bounds || project.isDemo) {
      setHasMapData(true);
    }
  
    window.addEventListener("dataLoaded", handleDemoLoaded);
  }, []);

  // Checks for updates to project settings
  React.useEffect(() => {
    const hasUpdatedProperties = hasMapData && renderUrl.imageSrc && !hasUpdatedProject;
    if (hasUpdatedProperties) {
      setHasUpdatedProject(true);
    }
  }, [project.settings]);


  // Check for render progress percentage updates when a render is triggered
  React.useEffect(() => {
    if (!requestId || !isRendering) return;

    // Start a timer to request an update for the progress every 100ms
    const interval = setInterval(async () => {
      try {
        const currentProgress = await getProgress(requestId);
        setProgress(currentProgress);

        // If the render is complete, delete the timer
        if (currentProgress >= 100) {
          clearInterval(interval);
        }
      } catch (error) {
        console.error("Error fetching progress:", error);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [requestId, isRendering]);

  /**
   * Function to reset project values
   */
  const handleNewProject = (newShadingName: string) => {
    setShowResetShadingModal(false);

    console.log("Map updated sucessfully");

    project.reset();
    project.projectName = newShadingName;
    setSettings(project.settings);
    setRenderUrl({
      imageSrc: "",
      imageHash: Date.now(),
      geoTiff: "",
      png: "",
      world: "",
      projection: "",
    });
    setDownloading(false);
    setAnchorEl(null);
    setRendering(false);
    setHasMapData(false);
    setRequestId("");
    setProgress(0);
    setHasUpdatedProject(false);

    // Check if sufficient credits
    if (userCredits <= 0) {
      setShowInsufficientFundsModal(true);
    }
  }

  /**
   * Function to bypass data download checks if demo data is selected
   */
  const handleDemoLoaded = () => {
    if (project.isDemo) {
      setHasMapData(true);
      handleRerender();
    }
  };

  /**
   * Function to handle the render image response from the back-end
   * @param response_obj
   */
  async function handleRenderResponse(response_obj: any) {
    if (response_obj.jpg) {
      // Successfully charge credit before displaying render
      const successPurchase = (project.isDemo) ? true : await spendUserCredit(authKey);
      if (successPurchase) {
        // If the response images and data is valid, update the front-end values and display the image
        const responseJpg = `data:image/jpg;base64,${response_obj.jpg}`;
        const responsePng = `data:image/png;base64,${response_obj.png}`;
        const responseTiff = `data:image/tiff;base64,${response_obj.geoTiff}`;
        const responseWorld = `data:text/plain;base64,${response_obj.world}`;
        const responseProjection = `data:text/plain;base64,${response_obj.projection}`;

        setRenderUrl({
          imageSrc: responseJpg,
          imageHash: Date.now(),
          geoTiff: responseTiff,
          png: responsePng,
          world: responseWorld,
          projection: responseProjection,
        });
        updatePageCredits();
        return;
      }

    // Otherwise if not valid img or unsuccessful payment, display an error message
    console.error("Setting as error");
    console.error(response_obj);
    setErrorMessage("Failed to process image")
    handleOpenRenderErrorModal();
    setRenderUrl({
      imageSrc: "error",
      imageHash: Date.now(),
      geoTiff: "error",
      png: "error",
      world: "error",
      projection: "error",
      });
    }
  }

  /**
   * Function to extract error message string from response
   * @param xmlString response string
   * @returns  Error message string
   */
  const parseError = (xmlString: string) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");
    const errorTag = xmlDoc.getElementsByTagName("error")[0];
    return errorTag.textContent || "Unknown error occurred.";
  };

  const handleRenderFail = (e: AxiosError) => {
    setHasMapData(false);
    setErrorMessage(`Error ${e.status}: ${e.message}`);
    setShowRenderErrorModal(true);
  }

  /**
   * Handle project name updates
   * @param event
   */
  const handleNameUpdate = (event: React.FocusEvent<HTMLInputElement>) => {
    // Get the text from the input box
    const inputShadingName = event.target.value;

    // Update the project name in the database
    const setNameInDB = async (inputShadingName: string) => {
      const newShadingName = (inputShadingName.length > 0) ? inputShadingName : "Untitled map";
      const success = await setShadingNameInDB(project.mapId, newShadingName);
      if (!success) {
        console.error("Name update failed");
      }
    };

    setShadingName(inputShadingName);
    setNameInDB(inputShadingName)
      .catch(console.error)
  };

  /* Callback function once render has completed */
  function onFinishedRender() {
    setRendering(false);
    setHasUpdatedProject(false);
    setProgress(0);
  }

  /**
   * Handler for render requests
   * FIXME: Needs clean up this logic
   */
  const handleRerender = () => {
    if (project.isDemo && project.settings) {
      // If the demo data is selected, send a render request to the back end with current settings
      const renderDemo = async (p: Project) => {
        const request_id = await startRequest();
        setRequestId(request_id);
        const response_obj = await processCachedTiff(
          "",
          p.settings,
          project.isDemo,
          request_id,
          "Mercator",
          "demo",
          "demo"
        );
        await handleRenderResponse(response_obj);
      };

      setRendering(true);
      renderDemo(project)
        .catch(handleRenderFail)
        .finally(onFinishedRender);
      return;
    }

    // Check if sufficient credits
    if (userCredits <= 0) {
      setShowInsufficientFundsModal(true);
      return;
    }

    // Confirm with user to render image
    if (sessionStorage.getItem(CLOSED_RENDER_PAYMENT) !== CLOSED_RENDER_PAYMENT) {
      setShowConfirmRenderModal(true);
      return;
    }

    // User project render
    if (
      project.hasApiKey() &&
      project.bounds &&
      project.settings &&
      project.elevationModel &&
      project.projection
    ) {
      // If not a demo, check if the data is already cached
      if (project.cached) {
        // Request back-end to render cached data with current settings
        const renderCachedData = async (p: Project) => {
          const request_id = await startRequest();
          setRequestId(request_id);

          console.log("Using cached data for bounds: " + project.cachedFilepath);
          // We have cached data that matches!
          const response_obj = await processCachedTiff(
            project.cachedFilepath,
            p.settings,
            project.isDemo,
            request_id,
            project.projection,
            authKey,
            project.mapId
          );

          await handleRenderResponse(response_obj);
        }
        setRendering(true);
        renderCachedData(project)
          .catch(handleRenderFail)
          .finally(onFinishedRender);

      } else {
        // Otherwise if the data is not cahced, download it and render
        const downloadAndRenderData = async (p: Project) => {
          const request_id = await startRequest();
          setRequestId(request_id);

          console.log("Calling Fetch");
          setDownloading(true);

          // First, grab the elevation data so we can cache it then use it for rendering
          const responseData = await fetchElevationData(
            p.apiKey,
            p.bounds,
            project.elevationModel,
            authKey,
            project.projection
          );

          console.log("Finished fetch");
          setDownloading(false);

          // Give error message if the download failed
          if (!responseData.responseOk) {
            const errorMessage = parseError(responseData.responseText);
            setErrorMessage(errorMessage)
            handleOpenRenderErrorModal();
            setRenderUrl({
              imageSrc: "error",
              imageHash: Date.now(),
              geoTiff: "error",
              png: "error",
              world: "error",
              projection: "error",
            });

          } else {
            // If the download succeeded, cache the data
            project.cached = true;
            project.cachedFilepath = responseData.filepath;
            // Use the downloaded data to render an image
            const response_obj = await processCachedTiff(
              responseData.filepath,
              p.settings,
              project.isDemo,
              request_id,
              project.projection,
              authKey,
              responseData.mapId
            );

            await handleRenderResponse(response_obj);
          }
        }
        setRendering(true);
        downloadAndRenderData(project)
          .catch(handleRenderFail)
          .finally(onFinishedRender);
      }
    }
  }

  // Interaction helper functions
  const handleCancel = () => { cancelRequest(requestId); };
  const handleClose = () => { setAnchorEl(null); };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (filetype: ImageFormat) => {
    handleClose();
    const requestIdStr = project.isDemo ? "demo" : requestId;
    try {
      zipShading(renderUrl, requestIdStr, filetype);
    }
    catch (e: unknown) {
      console.error("Unable to package shading in requested format.");
      console.error((e as Error).message);
    }
  };

  const handleOpenResetShadingModal = () => { setShowResetShadingModal(true); };
  const handleCloseConfirmNewProject = () => { setShowResetShadingModal(false); };

  const handleOpenRenderErrorModal = () => { setShowRenderErrorModal(true); };
  const handleCloseError = () => { setShowRenderErrorModal(false); };

  // Handlers for dialogue boxes
  const handleRender = () => (userCredits > 0) ? setShowConfirmRenderModal(true) : setShowInsufficientFundsModal(true);
  const handleConfirmRender = () => {
    setShowConfirmRenderModal(false);
    sessionStorage.setItem(CLOSED_RENDER_PAYMENT, CLOSED_RENDER_PAYMENT);
    handleRerender();
  };
  const handleCloseConfirmRender = () => {setShowConfirmRenderModal(false)};
  const handleCloseInsufficientFunds = () => setShowInsufficientFundsModal(false);

  const headerContent = (
    <section
      className="d-flex align-items-center justify-content-between px-2"
      style={{ width: "100%" }}
    >
      {/* Left Side (empty for spacing) */}
      <div style={{ flex: 1 }}></div>

      {/* Center - Project name */}
      {/* {isAuthenticated() && project.projectName && (
        <div style={{ flex: 1, textAlign: "center" }}>
          <h6 className="property-heading">{project.projectName}</h6>
        </div>
      )} */}

      {/* Right Side - Join Waitlist Button */}
      {/* <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        <Button
          className="icon-button m-2"
          color={"primary"}
          variant="outlined"
          onClick={handleJoinWaitlistClick}
          style={{ textTransform: 'none' }}
          sx={{
              height: "38px",
          }}
        >
          Join Waitlist
        </Button>
      </div> */}
    </section>
  );

  function RerenderButton() {
    return (
      <Button
        onClick={handleRerender}
        disabled={isControlsDisabled}
        className="refresh-button"
        variant="outlined"
        color="primary"
        size="large">
        <CachedIcon className="refresh-button__icon" />
        <span>Render</span>
      </Button>
    );
  }

  // FIXME: Clean this up for clearer logic
  const PrimaryDisplay = () => (
    isDownloading
      ? <ProgressSpinner text="Downloading" />
      : <>
        {/* Rendering indicator */}
        {isRendering && (
          <div style={{ position: 'absolute', zIndex: 10 }}>
            <LabelledProgressSpinner text="Rendering" value={progress} />
          </div>
        )}
        {/* Apply settings button */}
        {hasMapData && hasUpdatedProject && !isRendering &&
          <RerenderButton />}
        {(authData || project.isDemo) && renderUrl && renderUrl.imageSrc.length > 0
          ? <MapDisplay renderUrl={renderUrl} />
          : !isRendering && <NavigationTiles />}
        {/* <MapDisplay renderUrl={renderUrl} /> */}
        <span style={{position: 'absolute', bottom: 0, left: 5, fontSize: 12}}>© 2024 Monash University | <Link href={PRIVACY_POLICY_PAGE}>Privacy Policy</Link> | <Link href={INFO_PAGE}>Contact</Link> </span>
      </>
  );

  return <>
    <ProjectBar headerContent={headerContent}>
      <PanelGroup autoSaveId="conditional" direction="horizontal" className="background" >
        <Panel id="left" defaultSize={80} className="page-content d-flex justify-content-center align-items-center" style={{flexDirection: 'column'}}>
          <PrimaryDisplay />
        </Panel>
        <ShadingAdjustmentsPanel setSettings={setSettings} disabled={isControlsDisabled} />
      </PanelGroup>
    </ProjectBar>
    <ConfirmRenderModal isOpen={showConfirmRenderModal} onConfirm={handleConfirmRender} onCancel={handleCloseConfirmRender} userCredits={userCredits} />
    <InsufficientFundsModal isOpen={showInsufficientFundsModal} onCancel={handleCloseInsufficientFunds} userCredits={userCredits} />
    <ResetShadingModal open={showResetShadingModal} onConfirm={handleNewProject} onCancel={handleCloseConfirmNewProject} />
    <RenderErrorModal isOpen={showRenderErrorModal} onCancel={handleCloseError} errorMessage={errorMessage} />
  </>;
}

export default EditMapPage;
