// Written by: FIT3162 CS Team 1
// Last modified: 1/09/24
// Title: Map selection page

import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { Flags } from 'react-feature-flags';
import L from 'leaflet';
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  TextField
} from "@mui/material";

import { EDIT_MAP_PAGE } from "App";
import {
  fetchElevationData,
  getUserApiKey,
  setUserApiKey
} from "#libs/apis/backend";
import { CLOSED_RENDER_PAYMENT, SESSION_API_KEY } from "#libs/sessionStorageKeys";
import { DEFAULT_ELEVATION_MODEL, ElevationModel } from "#libs/Project";

import { ConfirmRenderModal, InsufficientFundsModal } from "#components/CreditsModals";
import DEMDropdown from "#components/DEMDropdown";
import MapSelector from "#components/MapSelector";
import { ProgressSpinner, ProgressSpinnerContainer } from "#components/ProgressStatus";
import ProjectBar from "#components/ProjectBar";
import { ProjectContext, CreditsContext, CreditsContextType } from "#components/Contexts";

import logo from "#assets/logo.png";
import "#styles/pages/DownloadMap";
import { getAreaSize } from "#libs/geo";
import { numberWithCommas } from "#libs/utils";

const MAX_AREA_SIZE = 3_000_000_000;  // FIXME: Update with target squared area size

/***
 * Dialog box for requesting OpenTopography API Key.
 */
function ApiKeyDialog({ isOpen, apiKeyHandler: apiKeyHandler }: { isOpen: boolean, apiKeyHandler: any }) {
  const auth = useAuthUser();
  const authData = auth();
  const authKey = authData?.authKey || "";

  const [key, setKey] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(isOpen);

  const navigate = useNavigate();
  const handleClose = () => setOpen(false);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setKey(e.target.value);

  // Close the dialogue box and return to previous page
  const handleCancel = () => {
    handleClose();
    navigate(EDIT_MAP_PAGE);
  };

  // Update the API key
  const handleSubmit = () => {
    const setApiKey = async () => {
      sessionStorage.setItem(SESSION_API_KEY, key)
      const result = await setUserApiKey(authKey, key);
    };
    setApiKey()
      .catch(console.error);

    apiKeyHandler(key);
    handleClose();
  };

  // Get API key from the database on load
  React.useEffect(() => {
    const updatePageApiKey = async () => {
      const userApiKey = await getUserApiKey(authKey);
      if (userApiKey === null || userApiKey === "" || userApiKey == undefined) {
        setOpen(true);
      }
    };
    updatePageApiKey()
      .catch(console.error)
  }, [isOpen]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm" PaperProps={{ className: "glass--dark solid-fill" }}>
      <DialogTitle>OpenTopography API Key</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`An API Key is needed to download grids from OpenTopography.org`}
          <br />
          <Link href="https://opentopography.org" target="_blank">
            Request an API key here.
          </Link>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="apiKey"
          label="OpenTopography Key"
          type="text"
          fullWidth
          variant="standard"
          onChange={handleChange}
          // sx={{color: 'rgb(187, 182, 182)',}}
          className="api-key-modal__input-label"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} className="neutral-button" >Cancel</Button>
        <Button onClick={handleSubmit} className="yes-button">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Description of elevation model used
 */
function ModelDesc() {
  return (
    <div>
      <div className="vstack">
        {/* <div> */}
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="https://hydro.iis.u-tokyo.ac.jp/~yamadai/MERIT_DEM/"
        >
          MERIT DEM
        </Link>
        {" 3 arc second, version 1.0.2,"}
        {/* </div> */}
        9 March 2018
      </div>
    </div>
  )
}

/**
 * Description of projection used
 * @param {
 *   width, 
 *   height
 * } 
 */
function ProjectionDesc({
  width,
  height
}: {
  width: number,
  height: number
}) {
  return (
    <div className="justify-center">
      <div className="vstack">
        {`Columns x rows: ${height} x ${width}`}
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="https://hydro.iis.u-tokyo.ac.jp/~yamadai/MERIT_DEM/"
        >
          Merit user name and password.
        </Link>
      </div>
    </div>
  )
}

/**
 * Display user credit balance
 * @param {
 *   credits
 * } 
 */
function CreditsDesc({
  credits
}: {
  credits: number
}) {
  return (
    <div className="justify-center">
      <div className="vstack" style={{ textWrap: "nowrap" }}>
        <Flags authorizedFlags={["enableStripe"]}>
          {`Remaining Eduard Credits: ${credits}`}
          <Link href="/credits">Buy Eduard Credits</Link>
        </Flags>
      </div>
    </div>
  )
}

/***
 * Greyed out backdrop preventing action whilst downloading from OpenTopography
 * @param isActive: Flag if download flag is active
 */
function RenderingBackdrop({ isActive }: { isActive: boolean }) {
  return (
    <Backdrop
      open={isActive}
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <ProgressSpinner text="Loading" />
    </Backdrop>
  );
}

/**
 * Download map page
 * @returns React element
 */
function DownloadMapPage(): JSX.Element {
  // Get user auth key
  const auth = useAuthUser();
  const authData = auth();
  const authKey = authData?.authKey || "";
  const { userCredits, updatePageCredits } = React.useContext<CreditsContextType>(CreditsContext);

  const [elevationModel, setElevationModel] = React.useState<ElevationModel>(DEFAULT_ELEVATION_MODEL);
  const [projection, setProjection] = React.useState("Mercator");
  const [bounds, setBounds] = React.useState<L.LatLngBounds | undefined>()
  const project = React.useContext(ProjectContext);
  const [apiKey, setApiKey] = React.useState<string>("");
  const [isValidKey, setValidKey] = React.useState<boolean>(true);
  const [isChecking, setIsChecking] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const [showConfirmRenderModal, setShowConfirmRenderModal] = useState(false);
  const [showInsufficientFunds, setShowInsufficientFunds] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const [httpStatusError, setStatusError] = useState<number | null>(null);


  // Handlers for dialogue boxes
  const handleOpenConfirmRender = () => {
    // Redirect to purchase credits
    if (userCredits <= 0) {
      setShowInsufficientFunds(true);
      return;
    }
    // Show render cost dialog if not seen during this session
    if (sessionStorage.getItem(CLOSED_RENDER_PAYMENT) !== CLOSED_RENDER_PAYMENT) {
      setShowConfirmRenderModal(true);
      return;
    } 
    
    var areaSize = bounds ? getAreaSize(bounds) : undefined;
    if (!bounds || (areaSize !== undefined && areaSize > MAX_AREA_SIZE)) {
      setApiError(`
        Selected area of size ${areaSize !== undefined ? numberWithCommas(areaSize) : 'unknown'} m^2 is too large.\n
        A maximum area of ${numberWithCommas(MAX_AREA_SIZE)} m^2 may be selected.\n
        please select a smaller area.
      `);
      setShowErrorModal(true);
      return;
    }
    
    handleDownload();
  };
  const handleCloseConfirmation = () => setShowConfirmRenderModal(false);
  const handleOpenError = () => setShowErrorModal(true);
  const handleCloseError = () => setShowErrorModal(false);
  const handleCloseInsufficientFunds = () => setShowInsufficientFunds(false);

  // Update API key and credit balance from database
  React.useEffect(() => {
    const updatePageApiKey = async () => {
      // Request backend api for key or try local storage
      var userApiKey = await getUserApiKey(authKey);
      if (userApiKey == null || userApiKey === '') {
        userApiKey = sessionStorage.getItem(SESSION_API_KEY) || "";
      }

      if (userApiKey !== "" && userApiKey != undefined) {
        setApiKey(userApiKey);
        setValidKey(true);
        project.apiKey = apiKey;
      } else {
        setValidKey(false);
      }
    };
    updatePageApiKey()
      .catch(console.error)
  }, [apiKey]);

  /**
   * Async function to handle the OpenTopography download request
   */
  const handleDownload = async () => {
    setApiError(null);

    try {
      // Attempt to download the elevation data
      setShowConfirmRenderModal(false);
      setIsChecking(true);
      const responseData = await fetchElevationData(
        apiKey,
        {
          north: bounds?.getNorth(),
          south: bounds?.getSouth(),
          east: bounds?.getEast(),
          west: bounds?.getWest(),
        },
        elevationModel,
        authKey,
        projection
      );

      setIsChecking(false);
      if (!responseData.responseOk) {
        // If an error occurs, display and error message and cancel the purchase
        const errorMessage = parseError(responseData.responseText);
        setStatusError(responseData.responseStatus)
        setApiError(errorMessage);
        // handleCloseConfirmation();
        handleOpenError();
        return; // Exit the function after setting the error
      }

      // Otherwise, cache the data
      if (isValidKey && bounds && elevationModel && projection) {
        project.bounds = bounds;
        project.projection = projection;
        project.elevationModel = elevationModel;
        project.cached = true;
        project.cachedFilepath = responseData.filepath;
        project.mapId = responseData.mapId;

        sessionStorage.setItem(CLOSED_RENDER_PAYMENT, CLOSED_RENDER_PAYMENT);
        navigate(EDIT_MAP_PAGE);
      }
    }
    catch (error) {
      console.error("Error fetching data:", error);
    }

  };

  /**
   * Function to extract error message string from response
   * @param xmlString response string
   * @returns  Error message string
   */
  const parseError = (xmlString: string) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");
    const errorTag = xmlDoc.getElementsByTagName("error")[0];
    return errorTag.textContent || "Unknown error occurred.";
  };

  return <>
    <ProjectBar>
      <div className="page-layout">
        <RenderingBackdrop isActive={isChecking} />
        <MapSelector setBounds={setBounds} />
        <Footer />
        <ApiKeyDialog isOpen={!isValidKey} apiKeyHandler={setApiKey} />
      </div>
    </ProjectBar>
    <ConfirmRenderModal 
      isOpen={showConfirmRenderModal} 
      onConfirm={handleDownload} 
      onCancel={handleCloseConfirmation} 
      userCredits={userCredits}
    />
    <InsufficientFundsModal
      isOpen={showInsufficientFunds}
      onCancel={handleCloseInsufficientFunds} 
      userCredits={userCredits}      
    />
    <OpenTopographyErrorModal
      isOpen={showErrorModal}
      errorMessage={apiError}
      httpCode={httpStatusError}
      onCancel={handleCloseError}
    />
  </>
  
  /**
   * Footer element
   */
  function Footer() {
    return (
      <div className="footer">
        <div className="panel glass--dark">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Elevation model selection */}
            <FormControl className="mx-2" sx={{ width: 195 }}>
              <DEMDropdown elevationModel={elevationModel} onChange={setElevationModel} />
            </FormControl>

            {/* Projection type selection */}
            <FormControl
              className="mx-2 me-3" sx={{ width: 130 }}
            >
              <TextField
                select
                inputProps={{ id: 'projection' }}
                // label="Projection"
                defaultValue={projection}
                value={projection}
                onChange={(e) => setProjection(e.target.value)}
                fullWidth
              >
                <InputLabel className="px-3 opacity-50">Projection</InputLabel>
                "Geographic", "Mercator"
                <MenuItem value={"Geographic"}>Geographic</MenuItem>
                <MenuItem value={"Mercator"}>Mercator</MenuItem>
              </TextField>
            </FormControl>

            {/* Cancel button */}
            <Button
              children="Cancel"
              href={EDIT_MAP_PAGE}
              className="mx-2 ms-3 neutral-button"
              variant="text"
              size="large"
            />
            {/* Purchase button */}
            <Button
              children={(userCredits > 0) ? "Load Area" : "Purchase"}
              onClick={handleOpenConfirmRender}
              disabled={!isValidKey || !bounds || !elevationModel || !projection}
              className="ms-2 yes-button"
              variant="text"
              size="large"
            />
          </div>
        </div>
      </div>
    )
  }

  /**
   * Error message modal
   * @param { isOpen, errorMessage, httpCode, onCancel } 
   */
  function OpenTopographyErrorModal({ isOpen, errorMessage, httpCode, onCancel }: {
    isOpen: boolean;
    errorMessage: string | null;
    httpCode: number | null;
    onCancel: () => void;
  }) {
    if (!isOpen) {
      return null;
    }

    return (
      <Dialog open={isOpen} fullWidth maxWidth="sm" PaperProps={{ className: "glass--dark solid-fill" }}>
        {/* <DialogTitle>Insufficient Credits</DialogTitle> */}
        <DialogContent sx={{ overflow: "hidden" }}>
          <h1>OpenTopography Server Error</h1>
          <hr />
          <div style={{ paddingInline: 30 }}>
            <img className="eduard-logo" src={logo} alt="Eduard icon" />
            <p>{errorMessage}</p>
            {httpCode && <p>HTTP status code: {httpCode}</p>}
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClick} style={{ color: "var(--link-color)" }}>Close</Button> */}
          <Button className="no-button" onClick={onCancel}>OK</Button>
        </DialogActions>
      </Dialog>
    );
  }
}


export default DownloadMapPage;