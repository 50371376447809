// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: App element

import React from 'react'; // Import React
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { 
  RequireAuth as ReactRequireAuth,
  useAuthUser, 
  useIsAuthenticated
} from 'react-auth-kit';
import { Flags } from 'react-feature-flags';

import { getUserCredits } from "#libs/apis/backend";
import ThemeController from "#components/ThemeController";
import { CreditsContext } from "#components/Contexts";

import DownloadMapPage from "#pages/DownloadMap";
import EditMapPage from "#pages/EditMapPage";
import PaymentPage from "#pages/PaymentPage";
import ShadingsPage from "#pages/ShadingsPage";
import UserProfilePage from "#pages/UserProfile";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";

/**
 * Constants for page links
 */
const EDIT_MAP_PAGE = "/";
const DOWNLOAD_MAP_PAGE = "/download-map";
const PAYMENT_PAGE = "/credits";
const USER_PROFILE_PAGE = "/profile";
const SHADINGS_PAGE = "/shadings";
const INFO_PAGE = "/info/";
const HELP_PAGE = "/help";
const UNSUPPORTED_PAGE = "/unsupported";

const PRIVACY_POLICY_PAGE = "https://eduard.earth";


/**
 * Safeguard the children with authentication
*/
function RequireAuth({children}: { children: JSX.Element}) {
  return (
    <Flags authorizedFlags={['enableAuthentication']}
      renderOn={() => <ReactRequireAuth loginPath={'/'}>{children}</ReactRequireAuth>}
      renderOff={() => children}
    />
  );
}


// Define the app page routes
function App() {
  const NO_CREDITS = 0;

  // States
  const [userCredits, setUserCredits] = React.useState(NO_CREDITS);

  const auth = useAuthUser();
  const authData = auth();
  const authKey = authData?.authKey || "";
  const isAuthenticated = useIsAuthenticated();
  
  const updatePageCredits = async () => {
    if (isAuthenticated()) {
      const credits_amount = await getUserCredits(authKey);
      setUserCredits(credits_amount);
    }
    else {
      setUserCredits(NO_CREDITS);
    }
  };

  React.useEffect(() => {
      // Update the credit balance display on load
      updatePageCredits()
        .catch(console.error)
    }, [auth, userCredits]);

  return (
    <ThemeController>
      <CreditsContext.Provider value={{userCredits, updatePageCredits}}>   
        <Router>
          <Routes>
            <Route path={EDIT_MAP_PAGE} element={<EditMapPage/>} />
            <Route path={INFO_PAGE} element={<iframe src={"info.html"}/>} />
            <Route path={HELP_PAGE} element={<iframe src={"help.html"}/>} />
            <Route path={UNSUPPORTED_PAGE} element={<iframe src={"unsupported.html"}/>} />
            <Route path={DOWNLOAD_MAP_PAGE} element={<RequireAuth><DownloadMapPage/></RequireAuth>} />
            <Route path={PAYMENT_PAGE} element={<RequireAuth><PaymentPage/></RequireAuth>} />
            <Route path={USER_PROFILE_PAGE} element={<RequireAuth><UserProfilePage/></RequireAuth>} />
            <Route path={SHADINGS_PAGE} element={<RequireAuth><ShadingsPage/></RequireAuth>} />
          </Routes>
        </Router>
      </CreditsContext.Provider>
    </ThemeController>
  );
}

export default App;
export { 
  EDIT_MAP_PAGE, 
  DOWNLOAD_MAP_PAGE,
  PAYMENT_PAGE, 
  USER_PROFILE_PAGE,
  SHADINGS_PAGE,
  INFO_PAGE,
  HELP_PAGE,
  UNSUPPORTED_PAGE,
  PRIVACY_POLICY_PAGE, 
}