// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Account menu dropdown

/**
 * From
 * https://mui.com/material-ui/react-menu/
 */
import * as React from "react";
import {
  Box,
  Button,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  Logout as LogoutIcon,
  TollTwoTone as PaymentIcon,
  BurstModeSharp as BurstModeSharpIcon,
} from "@mui/icons-material";
import {
  useAuthUser,
  useIsAuthenticated,
  useSignIn,
  useSignOut,
} from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { ProjectContext } from "#components/Contexts";
import Project from "#libs/Project";

import "#styles/components/ProjectBar";
import "#styles/glass"
import { EDIT_MAP_PAGE, PAYMENT_PAGE, SHADINGS_PAGE, USER_PROFILE_PAGE } from "App";
import axios from "axios";

/**
 * Account menu
 */
export default function AccountMenu() {
  const project = React.useContext<Project>(ProjectContext);

  // Menu anchor elements
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  //Callback function from logging out
  const navigate = useNavigate();
  const signIn = useSignIn();
  const signOut = useSignOut();
  const auth = useAuthUser();
  const authData = auth();
  const isAuthenticated = useIsAuthenticated();

  const [openWaitlistDialog, setOpenWaitlistDialog] = React.useState(false);
  const [hasJoined, setHasJoined] = React.useState(false);
  const [email, setEmail] = React.useState('');

  const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,  // NOTE: Combines URLS if not absolute URL: begins with "<scheme>://"" or "//" (protocol-relative url)
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
    }
  });

  // Existing code...

  const handleJoinWaitlistClick = () => {
    setOpenWaitlistDialog(true);
  };

  const handleWaitlistDialogClose = () => {
    setOpenWaitlistDialog(false);
  };

  const handleJoinWaitlist = async () => {
    try {
      await axios.post('/api/join_waitlist', { email });
      setHasJoined(true);
      setOpenWaitlistDialog(false);
      console.error('Joining waitlist:', email);
    } catch (error) {
      console.error('Error joining waitlist:', error);
    }
  };

  // Callback function from logging in
  function handleCallbackResponse(response: any) {
    const decoded = jwt_decode<JwtPayload>(response.credential); // Decode the values from the jwt response

    if (
      "email" in decoded &&
      "name" in decoded &&
      "sub" in decoded &&
      decoded.sub) {

      signIn({
        token: response.credential,  // Sign in token, not user tokens
        expiresIn: 1440,
        tokenType: "Bearer",
        authState: {
          authKey: decoded.sub,
          email: decoded.email,
          name: decoded.name,
        },
      })
      navigate(EDIT_MAP_PAGE);
    } else {
      //Throw error
      console.error("Error: not all user details are present")
    }
  }
  function handleSignOut() {
    signOut();
    googleLogout();
    project.reset();
    //navigate("/login");  // Should redirect?, needed to prevent max update
    handleClose();
  }

  // Navigation button callback functions
  const handleCredits = () => navigate(PAYMENT_PAGE);
  const handleProjects = () => navigate(SHADINGS_PAGE);
  const handleProfile = () => navigate(USER_PROFILE_PAGE);

  function handleNewProject() {
    project.reset();
    navigate(EDIT_MAP_PAGE);
  }

  const AccountMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        >
        {!isAuthenticated() ? (
          <MenuItem onClick={handleClose}>
            {/* If the user is not logged in, show the Google login button */}
            <GoogleLogin
              onSuccess={handleCallbackResponse}
              onError={() => { console.log("Login Failed"); }}
              useOneTap
              theme="filled_blue"
              shape="square"
            />
          </MenuItem>
        ) : (
          [
            <MenuItem key="account" onClick={handleProfile}>
              {/* Otherwise if logged in, show full navigation panel */}
              <Avatar />
              My account
            </MenuItem>,
            <Divider key="divider" />,
            <MenuItem key="shadings" onClick={handleProjects}>
              <ListItemIcon>
                <BurstModeSharpIcon fontSize="small" />
              </ListItemIcon>
              Shadings
            </MenuItem>,
            <MenuItem key="credits" onClick={handleCredits}>
              <ListItemIcon>
                <PaymentIcon fontSize="small" />
              </ListItemIcon>
              Credits
            </MenuItem>,
            <MenuItem key="signout" onClick={handleSignOut}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              Sign Out
            </MenuItem>,
          ]
        )}
      </Menu>
    );
  };

  return <>
     <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        <Button
          className="icon-button m-2"
          color={"primary"}
          variant="outlined"
          onClick={handleJoinWaitlistClick}
          disabled={hasJoined}
          style={{ textTransform: 'none' }}
          sx={{
              height: "38px",
              width: "150px",
              '&.Mui-disabled': {
                borderColor: 'rgba(255, 255, 255, 0.5)', // Faded white border
                color: 'rgba(255, 255, 255, 0.5)',        // Faded white text
                backgroundColor: 'transparent',            // Optional: Ensure background remains transparent
              },
          }}
        >
          {hasJoined ? 'Waitlist Joined' : 'Join Waitlist'}
        </Button>
    </div>

    <Dialog
      open={openWaitlistDialog}
      onClose={handleWaitlistDialogClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{ className: "glass--dark solid-fill" }}
    >
      <DialogTitle>
        Eduard Cloud Waitlist
        <hr />
      </DialogTitle>
      <DialogContent>
        <Typography
            variant="body1"
            color="white"
            gutterBottom
            sx={{ marginBottom: '20px' }}
        >
            Add your email address and get notified when Eduard Cloud is released.
        </Typography>
        <div style={{ paddingTop: '5px' }}>
            <TextField
            fullWidth
            variant="outlined"
            label="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            size="small"
            autoFocus
            autoComplete="off"
            InputProps={{
                style: { color: 'white' },
            }}
            InputLabelProps={{
                style: { color: 'white' },
            }}
            />
        </div>
        </DialogContent>
      <DialogActions sx={{ padding: '16px' }}>
        <Button onClick={handleWaitlistDialogClose} className="neutral-button" style={{ textTransform: 'none' }} sx={{ height: '36px'}} >Cancel</Button>
        <Button onClick={handleJoinWaitlist} className="yes-button" style={{ textTransform: 'none' }} sx={{ height: '36px'}}>Join</Button>
      </DialogActions>
    </Dialog>
  </>;
}
