import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const CREDITS_PAGE = "/credits";

/**
 * Confirmation modal
 * @param { isOpen, onConfirm, onCancel } 
 */
function ConfirmRenderModal({ isOpen, onConfirm, onCancel, userCredits }: {
	isOpen: boolean;
	onConfirm: () => void;
	onCancel: () => void;
	userCredits: number;
}) {

	return (
		<Dialog open={isOpen} fullWidth maxWidth="sm" PaperProps={{ className: "glass--dark solid-fill" }}>
			{/* <DialogTitle>Insufficient Credits</DialogTitle> */}
			<DialogContent sx={{ overflow: "hidden" }}>
				<h1>Load Area and Render</h1>
				<hr />
				<div style={{ paddingInline: 30, textWrap: 'nowrap'}}>
						<p>Loading this area and rendering a shading will cost one Eduard Credit.</p>
				</div>
			</DialogContent>
			<DialogActions>
				<Button className="neutral-button" onClick={onCancel}>Cancel</Button>
				<Button className="yes-button" onClick={onConfirm}>Load Area</Button>
			</DialogActions>
		</Dialog>
	);
}

/**
 * Insufficients funds modal
 * @param { isOpen, onCancel } 
 */
function InsufficientFundsModal({ isOpen, onCancel, userCredits }: {
	isOpen: boolean;
	onCancel: () => void;
	userCredits: number
}) {
	const navigate = useNavigate();
	const handlePurchaseCredits = () => navigate(CREDITS_PAGE);
	
	return (
		<Dialog open={isOpen} fullWidth maxWidth="sm" PaperProps={{ className: "glass--dark solid-fill" }}>
			{/* <DialogTitle>Insufficient Credits</DialogTitle> */}
			<DialogContent sx={{ overflow: "hidden" }}>
        <h1>Insufficient Credits</h1>
        <hr />
        <div style={{ paddingInline: 30 }}>
				 	<p>To load an area and render a shading, Eduard credits are required.</p>
        </div>
			</DialogContent>
			<DialogActions>
				{/* <Button onClick={handleClick} style={{ color: "var(--link-color)" }}>Close</Button> */}
				<Button className="neutral-button" onClick={onCancel}>Cancel</Button>
				<Button className="yes-button" onClick={handlePurchaseCredits}>Purchase credits</Button>
			</DialogActions>
		</Dialog>
	);
}

export { ConfirmRenderModal, InsufficientFundsModal };