// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Edit map page
"use client";
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";

/**
 * Create new shading dialog. Gets input name of the newly created shading.
 * @param { isOpen, onConfirm, onCancel }
 */
function ResetShadingModal({ open: isOpen, onConfirm, onCancel }: {
  open: boolean;
  onConfirm: (arg0: string) => void;
  onCancel: () => void;
}) {
  const DEFAULT_SHADING_NAME = "Untitled shading";
  const [shadingName, setShadingName] = React.useState(DEFAULT_SHADING_NAME);

  const handleChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const newShadingName = (e.target.value.length > 0) ? e.target.value : DEFAULT_SHADING_NAME;
    setShadingName(newShadingName);
  }
  const handleConfirm = () => onConfirm(shadingName);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" PaperProps={{ className: "glass--dark solid-fill" }}>
      <DialogTitle>Create Shading<hr /></DialogTitle>
      <DialogContent >
        <div style={{ paddingTop: '5px', height: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <p style={{textWrap: 'nowrap', marginRight: '20px',  fontSize: "2em"}}>New Shading Name:</p>
          <TextField fullWidth variant="outlined" defaultValue={shadingName} onBlur={handleChange} ></TextField>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} className="neutral-button">Cancel</Button>
        <Button onClick={handleConfirm} className="yes-button">Create New Shading</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResetShadingModal;